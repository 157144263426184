<template>
  <div>
    <h6 class="mb-1 mt-1 text-center">忘記密碼</h6>
    <p>請輸入您註冊的 Email，系統會發送密碼重置信</p>
    <validation-error-alert v-if="validationErrors" :errors="validationErrors"></validation-error-alert>
    <b-form @submit.prevent="" class="av-tooltip tooltip-label-bottom">
      <div class="form-group">
        <label class="float-label">Email</label>
        <input type="email" class="form-control" v-model="email" placeholder="電子郵件"/>
      </div>
      <b-button class="btn-block primary-button" type="submit" variant="primary" size="lg" :disabled="processing" @click="submit" style="margin-top:36px;">
        送出
      </b-button>
      <div class="mt-4">
        <router-link :to="{path: '/' + $route.params.org_code + '/liff/login'}">返回登入</router-link>
      </div>
    </b-form>
  </div>
</template>

<script>
import router from "@/router";
import axios from 'axios'

export default {
  data() {
    return {
      processing: false,
      loginError: false,
      email: null,
      validationErrors: null,
    }
  },
  methods: {
    async submit() {
      let _this = this;
      _this.processing = true;

      const orgCode = this.$route.params.org_code
      const url = process.env.VUE_APP_API_BASE_URL + `/${orgCode}/liff/forgotpassword`

      axios.post(url, {
        email: _this.email,
      }).then(response => {
        if (response.data.success === true) {
          this.$swal.fire({
            text: '密碼重置信發送成功，請至信箱確認',
            type: 'success'
          })
          router.push(`/${orgCode}/liff/login`);
        } else {
          this.$swal.fire({
            title: '重置密碼失敗',
            type: 'error'
          })
        }
      }).catch(error => {
        console.error(error);
        if (error.response.status && error.response.status === 422) {
          this.validationErrors = error.response.data.message;
        }
      }).finally(() => {
        _this.processing = false;
      });
    }
  }
}
</script>
